import React, { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import { Viewer, Worker, ScrollMode } from '@react-pdf-viewer/core';
import { highlightPlugin, HighlightArea, RenderHighlightsProps } from '@react-pdf-viewer/highlight';
import { Trigger } from '@react-pdf-viewer/highlight';

import Text from './base/Text';
import { ResultWithReference } from "./PDFModal";
import { deleteCoverageEntry, deleteMatchedPair, editCoverageEntry, editMatchedPair } from '../requests';
import { useCookies } from 'react-cookie';
import Input from './Input';
import Space from './base/Space';
import Button from './base/Button';

const PairItem = ({ matchedPairId, firstResult, secondResult, firstName, secondName, firstPresignedUrl, secondPresignedUrl, similar, onClick, selected, onDoubleClick, setRefresh, onScheduleClick }: { 
  matchedPairId: string,
  firstResult: ResultWithReference,
  secondResult: ResultWithReference,
  firstName: string,
  secondName: string,
  firstPresignedUrl: string,
  secondPresignedUrl: string,
  similar: boolean,
  onClick: () => void,
  selected: boolean,
  onDoubleClick: () => void,
  setRefresh: (b: boolean) => void,
  onScheduleClick: (r: ResultWithReference) => void
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [currentCoverageName, setCurrentCoverageName] = useState<string>("");
  const [matches, setMatches] = useState<boolean>(similar);
  const [backgroundColor, setBackgroundColor] = useState<string>("");
  const [firstDocumentLoaded, setFirstDocumentLoaded] = useState<boolean>(false);
  const [secondDocumentLoaded, setSecondDocumentLoaded] = useState<boolean>(false);
  
  const [firstValue, setFirstValue] = useState<string>("");
  const [firstSublimit, setFirstSublimit] = useState<string>("");
  const [firstRetention, setFirstRetention] = useState<string>("");
  const [firstDescription, setFirstDescription] = useState<string>("");
  const [firstSchedule, setFirstSchedule] = useState<string>("");

  const [secondValue, setSecondValue] = useState<string>("");
  const [secondSublimit, setSecondSublimit] = useState<string>("");
  const [secondRetention, setSecondRetention] = useState<string>("");
  const [secondDescription, setSecondDescription] = useState<string>("");
  const [secondSchedule, setSecondSchedule] = useState<string>("");

  const resetValues = () => {
    setFirstValue(firstResult?.keys["value"] ?? "");
    setFirstSublimit(firstResult?.keys["limit"] ?? "");
    setFirstRetention(firstResult?.keys["retention"] ?? "");
    setFirstDescription(firstResult?.keys["description"] ?? "");
    setFirstSchedule(firstResult?.keys["schedule"] ?? "");

    setSecondValue(secondResult?.keys["value"] ?? "");
    setSecondSublimit(secondResult?.keys["limit"] ?? "");
    setSecondRetention(secondResult?.keys["retention"] ?? "");
    setSecondDescription(secondResult?.keys["description"] ?? "");
    setSecondSchedule(secondResult?.keys["schedule"] ?? "");
  }

  useEffect(() => {
    setBackgroundColor(matches
      ? (selected ? "#c5e1a5" : (isHovered ? "#d1e7b7" : "#dcedc9"))
      : (selected ? "#ffa3a6" : (isHovered ? "#ffb5b7" : "#ffc8c9")));
  }, [matches, selected, isHovered])

  useEffect(() => {
    setMatches(similar);
  }, [similar])

  useEffect(() => {
    setCurrentCoverageName(firstResult?.coverageName || secondResult?.coverageName || "");
  }, [firstResult?.coverageName, secondResult?.coverageName])

  useEffect(() => {
    resetValues();
  }, [firstResult?.keys, secondResult?.keys])

  const onSaveEdit = async () => {
    if (firstResult.coverageType == "generic") {
      await editCoverageEntry(cookies['user-id'], firstResult.id, { 'value': firstValue }, currentCoverageName);
      await editCoverageEntry(cookies['user-id'], secondResult.id, { 'value': secondValue }, currentCoverageName);
    } else if (firstResult.coverageType == "coverage") {
      await editCoverageEntry(cookies['user-id'], firstResult.id, { 'limit': firstSublimit, 'retention': firstRetention }, currentCoverageName);
      await editCoverageEntry(cookies['user-id'], secondResult.id, { 'limit': secondSublimit, 'retention': secondRetention }, currentCoverageName);
    }

    setRefresh(true);
    setEditing(false);
  }

  const onDelete = async () => {
    await deleteMatchedPair(cookies['user-id'], matchedPairId);

    setRefresh(true);
    setEditing(false);
  }

  const handleSwitch = async () => {
    await editMatchedPair(cookies['user-id'], matchedPairId, !matches);
    setMatches(!matches);
  }


  const renderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {[firstResult].map((result) => (
        <React.Fragment key={result.id}>
          {/* Check if the highlightArea's pageIndex matches the current pageIndex */}
          {result.highlightArea.pageIndex === props.pageIndex && (
            <div
              style={Object.assign(
                {},
                {
                  background: 'yellow',
                  opacity: 0.4,
                },
                // Apply position and dimensions using getCssProperties function
                props.getCssProperties(result.highlightArea, props.rotation)
              )}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );

  const secondRenderHighlights = (props: RenderHighlightsProps) => (
    <div>
      {[secondResult].map((result) => (
        <React.Fragment key={result.id}>
          {/* Check if the highlightArea's pageIndex matches the current pageIndex */}
          {result.highlightArea.pageIndex === props.pageIndex && (
            <div
              style={Object.assign(
                {},
                {
                  background: 'yellow',
                  opacity: 0.4,
                },
                // Apply position and dimensions using getCssProperties function
                props.getCssProperties(result.highlightArea, props.rotation)
              )}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
  
  const highlightPluginInstance = highlightPlugin({renderHighlights, trigger: Trigger.None});
  const secondHighlightPluginInstance = highlightPlugin({renderHighlights: secondRenderHighlights, trigger: Trigger.None});
  const { jumpToHighlightArea } = highlightPluginInstance;
  const { jumpToHighlightArea: secondJumpToHighlightArea } = secondHighlightPluginInstance;

  useEffect(() => {
    if (firstDocumentLoaded) {
      jumpToHighlightArea(firstResult.highlightArea);
      setFirstDocumentLoaded(false);
    }
  }, [firstDocumentLoaded])

  useEffect(() => {
    if (secondDocumentLoaded) {
      secondJumpToHighlightArea(secondResult.highlightArea);
      setSecondDocumentLoaded(false);
    }
  }, [secondDocumentLoaded])
  
  return (
    (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            //height: "40px",
            cursor: "pointer",
            borderRadius: "8px",
            padding: "10px",
            transition: "background-color 0.25s",
            backgroundColor: backgroundColor,
          }}
          onClick={selected ? onDoubleClick : onClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
            <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%"
            }}>
              { !editing && (
                <Text size={20} weight={selected ? 700 : 400}>
                  {currentCoverageName}
                </Text>
              )}
              { editing && (
                <Input value={currentCoverageName} setValue={setCurrentCoverageName} onClick={(e) => e.stopPropagation() } extraStyles={{ fontWeight: 700, backgroundColor: backgroundColor }} />
              )}
              <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "15px",
                marginRight: "30px"
              }}>
                { (isHovered || editing) && (
                  <>
                    { !editing && (<img src={process.env.PUBLIC_URL + "/assets/edit.png"} style={{ cursor: "pointer", width: "20px", borderRadius: "8px" }} onClick={(e) => { e.stopPropagation(); setEditing(true); }} /> )}
                    { editing && (<img src={process.env.PUBLIC_URL + "/assets/cancel.png"} style={{ cursor: "pointer", width: "20px", borderRadius: "8px" }} onClick={(e) => { 
                      e.stopPropagation();
                      setEditing(false);
                      setCurrentCoverageName(firstResult?.coverageName || secondResult?.coverageName || "");
                      resetValues();
                    }} /> )}
                    { editing && (<img src={process.env.PUBLIC_URL + "/assets/green-check.png"} style={{ cursor: "pointer", width: "20px", borderRadius: "8px" }} onClick={(e) => { e.stopPropagation(); onSaveEdit(); }} /> )}
                    <img src={process.env.PUBLIC_URL + "/assets/trash-can.png"} style={{ cursor: "pointer", width: "15px", borderRadius: "8px", padding: "3px" }} onClick={(e) => { e.stopPropagation(); onDelete(); }} />
                  </>
                )}
                { (!isHovered && !editing) && (
                  <Text size={20} weight={700}>
                    {matches ? "SAME" : "DIFFERENT"}
                  </Text>
                )}
                <img src={process.env.PUBLIC_URL + "/assets/right-arrow.png"} style={{ cursor: "pointer", width: "20px", height: "20px", borderRadius: "8px", rotate: selected ? "90deg" : undefined}} onClick={() => setEditing(true)} />
              </div>
            </div>
        </div>
        { selected && (
          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            borderLeft: '3px solid grey',
            padding: "10px 30px",
            marginLeft: "15px",
          }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
              <Text size={16}>
                Matches?
              </Text>
              <Switch checked={matches} onChange={handleSwitch} />
            </div>
            { (firstResult.coverageType == "generic" || secondResult.coverageType == "generic") && (
              <>
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  gap: "20px",
                  height: "80%",
                }}>
                  <div style={{ display: "flex", flexDirection: "column", width: "53%"}}>
                    <Text size={20} weight={700}>
                      Policy #1: {firstName}
                    </Text>
                    <Space px={30} />
                    { !editing && (
                      <Text size={20} weight={selected ? 700 : 400}>
                        {firstValue}
                      </Text>
                    )}
                    { editing && (
                      <Input value={firstValue} setValue={setFirstValue} onClick={(e) => e.stopPropagation() } extraStyles={{ fontWeight: 700 }} />
                    )}
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", width: "50%"}}>
                    <Text size={20} weight={700}>
                      Policy #2: {secondName}
                    </Text>
                    <Space px={30} />
                    { !editing && (
                      <Text size={20} weight={selected ? 700 : 400}>
                        {secondValue}
                      </Text>
                    )}
                    { editing && (
                      <Input value={secondValue} setValue={setSecondValue} onClick={(e) => e.stopPropagation() } extraStyles={{ fontWeight: 700 }} />
                    )}
                  </div>
                </div>
                
              </>
            )}
            { (firstResult.coverageType == "coverage" || secondResult.coverageType == "coverage") && (
              <>
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  gap: "20px",
                  height: "80%",
                }}>
                  <div style={{ display: "flex", flexDirection: "column", width: "53%" }}>
                    <Text size={20} weight={700}>
                      Policy #1: {firstName}
                    </Text>
                    <Space px={10} />
                    <div style={{ display: "inline-block" }}>
                      <div style={{ display: "inline-block" }}>
                        <Text size={20}>
                          <b>Sublimit</b>: 
                        </Text>
                      </div>
                      <div style={{ marginLeft: "5px", display: "inline-block" }}>
                        { !editing && (
                          <Text size={20}>
                            {" " + firstSublimit}
                          </Text>
                        )}
                        { editing && (
                          <Input value={firstSublimit} setValue={setFirstSublimit} onClick={(e) => e.stopPropagation() } />
                        )}
                      </div>
                    </div>
                    <div style={{ display: "inline-block" }}>
                      <div style={{ display: "inline-block" }}>
                        <Text size={20}>
                          <b>Retention</b>: 
                        </Text>
                      </div>
                      <div style={{ marginLeft: "5px", display: "inline-block" }}>
                        { !editing && (
                          <Text size={20}>
                            {" " + firstRetention}
                          </Text>
                        )}
                        { editing && (
                          <Input value={firstRetention} setValue={setFirstRetention} onClick={(e) => e.stopPropagation() } />
                        )}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                    <Text size={20} weight={700}>
                      Policy #2: {secondName}
                    </Text>
                    <Space px={10} />
                    <div style={{ display: "inline-block" }}>
                      <div style={{ display: "inline-block" }}>
                        <Text size={20}>
                          <b>Sublimit</b>: 
                        </Text>
                      </div>
                      <div style={{ marginLeft: "5px", display: "inline-block" }}>
                        { !editing && (
                          <Text size={20}>
                            {" " + secondSublimit}
                          </Text>
                        )}
                        { editing && (
                          <Input value={secondSublimit} setValue={setSecondSublimit} onClick={(e) => e.stopPropagation() } />
                        )}
                      </div>
                    </div>
                    <div style={{ display: "inline-block" }}>
                      <div style={{ display: "inline-block" }}>
                        <Text size={20}>
                          <b>Retention</b>: 
                        </Text>
                      </div>
                      <div style={{ marginLeft: "5px", display: "inline-block" }}>
                        { !editing && (
                          <Text size={20}>
                            {" " + secondRetention}
                          </Text>
                        )}
                        { editing && (
                          <Input value={secondRetention} setValue={setSecondRetention} onClick={(e) => e.stopPropagation() } />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            { (firstResult.coverageType == "endorsement" || secondResult.coverageType == "endorsement") && (
              <>
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  gap: "20px",
                  height: "80%",
                }}>
                  <div style={{ display: "flex", flexDirection: "column", width: "53%"}}>
                    <Text size={20} weight={700}>
                      Policy #1: {firstName}
                    </Text>
                    <Space px={30} />
                    { !editing && (
                      <Text size={20} weight={selected ? 700 : 400}>
                        {firstDescription}
                      </Text>
                    )}
                    { editing && (
                      <Input inputType="textarea" value={firstDescription} setValue={setFirstDescription} />
                    )}
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", width: "50%"}}>
                    <Text size={20} weight={700}>
                      Policy #2: {secondName}
                    </Text>
                    <Space px={30} />
                    { !editing && (
                      <Text size={20} weight={selected ? 700 : 400}>
                        {secondDescription}
                      </Text>
                    )}
                    { editing && (
                      <Input inputType="textarea" value={secondDescription} setValue={setSecondDescription} />
                    )}
                  </div>
                </div>
                
              </>
            )}
            { (firstResult.coverageType == "schedule" || secondResult.coverageType == "schedule") && (
              <div style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
                gap: "20px",
                height: "80%",
              }}>
                <div style={{ display: "flex", flexDirection: "column", width: "53%" }}>
                  <Text size={20} weight={700}>
                    Policy #1: {firstName}
                  </Text>
                  <Space px={10} />
                  <Button border="1px solid black" color="white" padding="10px" onClick={() => onScheduleClick(firstResult)}>
                    <Text size={20}>
                      View Schedule
                    </Text>
                  </Button>
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
                  <Text size={20} weight={700}>
                    Policy #2: {secondName}
                  </Text>
                  <Space px={10} />
                  <Button border="1px solid black" color="white" padding="10px" onClick={() => onScheduleClick(secondResult)}>
                    <Text size={20}>
                      View Schedule
                    </Text>
                  </Button>
                </div>
              </div>
            )}
            <Space px={20} />
            <div style={{
              display: "flex",
              flexDirection: "row",
              width: "105%",
              justifyContent: "center",
              gap: "20px",
              height: "80%",
            }}>
              <div style={{ width: "105%", height: "100%" }}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">  
                  {firstPresignedUrl && (
                    <div style={{
                      border: '1px solid rgba(0, 0, 0, 0.3)',
                      width: "100%",
                      height: "400px",
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
                    }}>
                      <Viewer fileUrl={firstPresignedUrl} plugins={[highlightPluginInstance]} onDocumentLoad={() => setFirstDocumentLoaded(true)}/>
                    </div>
                  )}
                </Worker>
              </div>
              <div style={{ width: "105%", height: "100%" }}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">  
                  {secondPresignedUrl && (
                    <div style={{
                      border: '1px solid rgba(0, 0, 0, 0.3)',
                      width: "100%",
                      height: "400px",
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
                    }}>
                      <Viewer fileUrl={secondPresignedUrl} plugins={[secondHighlightPluginInstance]} onDocumentLoad={() => setSecondDocumentLoaded(true)} />
                    </div>
                  )}         
                </Worker>
              </div>
              <Space px={50} />
            </div>
            
          </div>
        )}
      </>
    )
  );
};

export default PairItem;
