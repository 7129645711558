import React from 'react';
import { components } from 'react-select';
import CopyIcon from './CopyIcon';

import Text from './base/Text';
import Space from './base/Space';
import UploadFile from './UploadFile';

const NewDocument = ({ documentsLength, retrieveDocuments, category, clientId, associatedDocumentId, isList }: {
  documentsLength: number,
  retrieveDocuments: () => void,
  category: string,
  clientId: string
  associatedDocumentId?: string,
  isList?: boolean
}) => {
  return (
    <>
    { documentsLength == 0 && (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "12px",
          marginBottom: "35px"
        }}
      >
        { documentsLength == 0 && (
          <div style={{ marginTop: "100px" }}>
            <Text size={30} color="#787878" align="center">
              { category != "endorsement" && category != "schedule" && "To get started, add your first policy below:" }
              { category == "endorsement" && "Add an associated endorsement below:" }
              { category == "schedule" && "Add an associated schedule below:" }
            </Text>
            <Space px={10} />
          </div>
        )}
        <div style={{ display: "flex", flexDirection: "row", gap: "30px", justifyContent: "center", alignItems: "center" }}>
          { documentsLength == 0 && (
            <>
              { category == "application" && (
                <div style={{ marginBottom: "40px" }}>
                  <Text size={12}>
                    — or —
                  </Text>
                </div>
              )}
              <UploadFile key="firstUpload" retrieveDocuments={retrieveDocuments} category={category} shiftUp={true} clientId={clientId} associatedDocumentId={associatedDocumentId} isList={false} />
            </>
          )}
        </div>
      </div>
      )}
      { documentsLength !== 0 && (
        <UploadFile key="secondUpload" retrieveDocuments={retrieveDocuments} category={category} shiftUp={false} clientId={clientId} associatedDocumentId={associatedDocumentId} isList={isList} />
      )}
    </>
  );
};

export default NewDocument;

