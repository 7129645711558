import React, { useState, useRef, useEffect, ReactNode } from "react";
import Switch from '@mui/material/Switch';

import Modal from "./Modal";
import Text from "./base/Text";
import Divider from "./base/Divider";
import Document, { DocumentStatus, DocumentType } from "./Document";
import Button from "./base/Button";
import { analyzePolicy, comparePolicyCoverage, deleteDocument, generateEditedReport, generateProposal, getDocumentResults, getPresignedUrl, setDisplayName, updateCoverageLines } from "../requests";
import { useCookies } from "react-cookie";
import AISearch from "./AISearch";
import LoadingSpinner from "./base/Spinner";
import { Viewer, Worker, ScrollMode } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Space from "./base/Space";
import './base/Button.css';
import FileDrop from "./FileDrop";
import NewDocument from "./NewDocument";
import Container from "./Container";

import '@react-pdf-viewer/highlight/lib/styles/index.css';

import { highlightPlugin, HighlightArea, RenderHighlightsProps } from '@react-pdf-viewer/highlight';
import { DocumentsContainer } from "../Documents";

export interface ResultWithReference {
  // The generated unique identifier
  id: string;

  // The result content
  coverageName: string;

  coverageType: string;

  coverageLine: string;

  keys: any;

  // The list of highlight areas
  highlightArea: HighlightArea;

  // String of Page Ref information
  pageIndex: string;
}

const FirstAnalysisModal = ({ setModalOpen, modalOpen, document, documents, setDocument, retrieveDocuments, setSuccessMessage, setErrorMessage, secondDocument, setSecondDocument, setComparisonModalOpen, agencyId, clientId, setClientId }: { 
  setModalOpen: (b: boolean) => void,
  modalOpen: boolean,
  document: DocumentType | null,
  documents: DocumentType[],
  retrieveDocuments: () => void,
  setDocument: (d: DocumentType | null) => void,
  secondDocument: DocumentType | null,
  setSecondDocument: (d: DocumentType | null) => void,
  setSuccessMessage: (s: string) => void,
  setErrorMessage: (s: string) => void,
  setComparisonModalOpen: (b: boolean) => void,
  agencyId: string,
  clientId: string,
  setClientId: (s: string) => void,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [selectedDocument, setSelectedDocument] = useState<DocumentType | null>(null);
  const [page, setPage] = useState<string>("compare");
  const [generationStarted, setGenerationStarted] = useState<string | null>(null);
  const [selectedLines, setSelectedLines] = useState<string[]>([]);
  const [objective, setObjective] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [editedName, setEditedName] = useState<string>("");
  const [localDisplayName, setLocalDisplayName] = useState<string>("");
  const [selectedLine, setSelectedLine] = useState<string>("");

  const onModalCloseInternal = () => {
    setPage("compare");
    setGenerationStarted(null);
  }

  const onGenerate = async () => {
    setGenerationStarted("started");
    const policyResp = await analyzePolicy(cookies['user-id'], "", document?.instanceId ?? "", "internal", true);
    if (policyResp.body["bad_policy"]) {
      setErrorMessage("We are unable to generate reports for this coverage line. We apologize for this inconvenience.");
      setGenerationStarted(null);
    }
    retrieveDocuments();
    setModalOpen(false);
    onModalCloseInternal();
  }

  useEffect(() => {
    setLocalDisplayName(document?.name ?? "");
  }, [document, document?.name])

  useEffect(() => {
    setEditedName(document?.name ?? "");
  }, [document, document?.name])

  useEffect(() => {
    setSelectedLines(document?.lines ?? []);
    if (document?.lines && document?.lines.length > 0) {
      setSelectedLine(document?.lines[0]);
    }
  }, [document, document?.lines, modalOpen])

  const onDocumentClick = (d2: DocumentType) => {
    setSelectedDocument(d2);
  }
  
  const onCompareSelect = async () => {
    setDocument(selectedDocument);
    setPage("compare");
  }

  return (
    <Modal setModalOpen={setModalOpen} modalOpen={modalOpen} id="firstAnalysisModal" onModalClose={() => {
      onModalCloseInternal();
    }} extraStyles={{
      padding: "40px",
      border: "1px solid #B9B9B9",
      boxShadow: "0 8px 6px -6px gray, -3px 0 6px -6px gray, 3px 0 6px -6px gray",
      width: "90%",
      height: "80%",
      backgroundColor: "#ffffff",
      overflowY: "scroll",
      position: "relative"
    }}>
      { page == "compare" && (
        <>
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px"
          }}>
            <Text size={40} weight={600} align="center">
              Select a policy to generate a report on.
            </Text>        
          </div>
          <Divider />
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "60px",
            alignItems: "center",
            justifyContent: "center",
            height: "70%"
          }}>
            { !document && (
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                width: "280px",
                height: "280px",
                border: "1px solid black",
                cursor: "pointer"
              }} onClick={() => setPage("selecting")}>
                <img style={{ width: "75px", height: "75px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/plus.png"} />
              </div>
            )}
            { document && (
              <div style={{
                position: "relative"
              }}>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                  width: "280px",
                  height: "280px",
                }}>
                  <img style={{ width: "150px", height: "150px", cursor: "pointer" }} src={ process.env.PUBLIC_URL + "/assets/pdf.png"} />
                  <Text size={30} weight={600} align="center">
                    {document?.name && document?.name.length > 17 ? document?.name.slice(0, 17) + "..." : document?.name}
                  </Text>
                </div>
                <img style={{ width: "50px", height: "50px", cursor: "pointer", position: "absolute", top: "0px", right: "0px" }} src={ process.env.PUBLIC_URL + "/assets/cancel.png"} onClick={() => setDocument(null)} />
              </div>
            )}
          </div>
          <div style={{ position: "absolute", bottom: "30px", right: "30px", display: "flex", flexDirection: "row", gap: "20px" }}>
            { !generationStarted && (
              <>
                <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => {
                  onModalCloseInternal();
                  setModalOpen(false);
                }}>
                  <Text size={20} weight={600} color="#000000">
                    Cancel
                  </Text>
                </Button>
                <Button width="200px" height="55px" greyed={!document} onClick={onGenerate}>
                  <Text size={20} weight={600} color="white">
                    Generate Report
                  </Text>
                </Button>
              </>
            )}
            { generationStarted && (
              <LoadingSpinner />
            )}
          </div>
        </>
      )}
      { page == "selecting" && (
        <>
          <Text size={35} weight={600}>
            Select a policy to compare
          </Text>
          <Divider />
          <DocumentsContainer clientId={clientId} category="policy" documents={documents} retrieveDocuments={retrieveDocuments} onDocumentClick={onDocumentClick} setClientId={setClientId} setErrorMessage={setErrorMessage} selecting selectedDocument={selectedDocument ?? undefined} />            
          <div style={{ display: "flex", flexDirection: "row", gap: "20px", justifyContent: "flex-end" }}>
            <Button width="150px" height="50px" color="#ffffff" border="2px solid #000000" onClick={() => setPage("compare")}>
              <Text size={20} weight={600} color="#000000">
                Cancel
              </Text>
            </Button>
            <Button greyed={!selectedDocument} onClick={onCompareSelect}>
              <Text size={16} weight={600} color="white">
                Select Document
              </Text>
            </Button>
          </div>
        </>
      )}
    </Modal>
  )
};

export default FirstAnalysisModal;
