
import React, { useState, useRef, useEffect } from "react";
import Select, { components } from 'react-select';
import Text from "./Text";

const customStyles = {
	control: (provided, state) => ({
		...provided,
		//width: '314px',  // Set your desired width here
		height: '45px',
		fontSize: '18px',
		fontFamily: 'Assistant',
	}),
	option: (provided, state) => ({
		...provided,
		fontFamily: 'Assistant', // Set your desired font family
		fontSize: '18px',
	}),
	singleValue: (provided) => ({
		...provided,
		fontFamily: 'Assistant', // Ensure consistency in selected value
		fontSize: '18px',
	}),
	noOptionsMessage: (provided) => ({
		...provided,
		fontFamily: 'Assistant', // Ensure consistency in selected value
		fontSize: '18px',
	})
};

const Dropdown = ({ options, option, setOption, label, width }: { options: any[], option: string, setOption: (s: string) => void, label: string, width?: string }) => {
	// const [localOptions, setLocalOptions] = useState<any[]>([]);

	// useEffect(() => {
	// 	setLocalOptions(options.map(o => ({
	// 		label: o,
	// 		value: o
	// 	})))

	// }, [options])

  return (
		<div style={{
			display: "flex",
			flexDirection: "column",
			gap: "10px",
      width: width ? width : undefined
		}}>
			<Text size={16} weight={600}>
				{label}
			</Text>
			<Select
				value={options.find(c => c.value == option)}
				onChange={(option) => {
					setOption(option?.value ?? "");
				}}
				options={options}
				styles={customStyles}
				isSearchable={false}
			/>
		</div>

  )
};

export default Dropdown;


