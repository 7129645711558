import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import Text from './base/Text';
import { ResultWithReference } from "./PDFModal";
import { deleteCoverageEntry, editCoverageEntry } from '../requests';
import { useCookies } from 'react-cookie';
import Input from './Input';
import Button from './base/Button';

const ResultItem = ({ result, onClick, selected, onDoubleClick, setRefresh, onScheduleClick }: {
  result: ResultWithReference,
  onClick: () => void,
  selected: boolean,
  onDoubleClick: () => void,
  setRefresh: (b: boolean) => void,
  onScheduleClick: (r: ResultWithReference) => void
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [currentCoverageName, setCurrentCoverageName] = useState<string>("");
  const [currentValue, setCurrentValue] = useState<string>("");
  const [currentSublimit, setCurrentSublimit] = useState<string>("");
  const [currentRetention, setCurrentRetention] = useState<string>("");
  const [currentDescription, setCurrentDescription] = useState<string>("");
  const [currentSchedule, setCurrentSchedule] = useState<string>("");

  const resetValues = () => {
    setCurrentValue(result?.keys["value"] ?? "");
    setCurrentSublimit(result?.keys["limit"] ?? "");
    setCurrentRetention(result?.keys["retention"] ?? "");
    setCurrentDescription(result?.keys["description"] ?? "");
    setCurrentSchedule(result?.keys["schedule"] ?? "");
  }

  useEffect(() => {
    setCurrentCoverageName(result?.coverageName ?? "");
  }, [result?.coverageName])

  useEffect(() => {
    resetValues();
  }, [result?.keys])

  const onSaveEdit = async () => {
    setEditing(false);

    if (result.coverageType == "generic") {
      await editCoverageEntry(cookies['user-id'], result.id, { 'value': currentValue }, currentCoverageName);
    } else if (result.coverageType == "coverage") {
      await editCoverageEntry(cookies['user-id'], result.id, { 'limit': currentSublimit, 'retention': currentRetention }, currentCoverageName);
    }

    setRefresh(true);
  }

  const onDelete = async () => {
    await deleteCoverageEntry(cookies['user-id'], result.id);

    setRefresh(true);
    setEditing(false);
  }
  
  return (
    (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            //height: "40px",
            cursor: "pointer",
            borderRadius: "8px",
            padding: "10px",
            transition: "background-color 0.25s",
            backgroundColor: selected ? "#E7E7E7" : (isHovered ? "#ECECEC" : "#F6F6F6"),
          }}
          onClick={selected ? onDoubleClick : onClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
            <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%"
            }}>
              { !editing && (
                <Text size={20} weight={selected ? 700 : 400}>
                  {currentCoverageName}
                </Text>
              )}
              { editing && (
                <Input value={currentCoverageName} setValue={setCurrentCoverageName} onClick={(e) => e.stopPropagation() } extraStyles={{ fontWeight: 700, backgroundColor: selected ? "#E7E7E7" : (isHovered ? "#ECECEC" : undefined) }} />
              )}
              <div style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "15px",
                marginRight: "30px"
              }}>
                { (isHovered || editing) && (
                  <>
                    { !editing && (<img src={process.env.PUBLIC_URL + "/assets/edit.png"} style={{ cursor: "pointer", width: "20px", borderRadius: "8px" }} onClick={(e) => { e.stopPropagation(); setEditing(true); }} /> )}
                    { editing && (<img src={process.env.PUBLIC_URL + "/assets/cancel.png"} style={{ cursor: "pointer", width: "20px", borderRadius: "8px" }} onClick={(e) => { 
                      e.stopPropagation();
                      setCurrentCoverageName(result?.coverageName ?? "");
                      resetValues();
                      setEditing(false);
                    }} /> )}
                    { editing && (<img src={process.env.PUBLIC_URL + "/assets/green-check.png"} style={{ cursor: "pointer", width: "20px", borderRadius: "8px" }} onClick={(e) => { e.stopPropagation(); onSaveEdit(); }} /> )}
                    <img src={process.env.PUBLIC_URL + "/assets/trash-can.png"} style={{ cursor: "pointer", width: "15px", borderRadius: "8px", padding: "3px" }} onClick={(e) => { e.stopPropagation(); onDelete(); }} />
                  </>
                )}
                <img src={process.env.PUBLIC_URL + "/assets/right-arrow.png"} style={{ cursor: "pointer", width: "20px", height: "20px", borderRadius: "8px", rotate: selected ? "90deg" : undefined}} onClick={() => setEditing(true)} />
              </div>
              
            </div>
            
        </div>
        { selected && (
          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            borderLeft: '3px solid grey',
            padding: "10px 30px",
            marginLeft: "15px",
          }}>
            { result.coverageType == "generic" && (
              <>
                { !editing && (
                  <Text size={20} weight={selected ? 700 : 400}>
                    {currentValue}
                  </Text>
                )}
                { editing && (
                  <Input value={currentValue} setValue={setCurrentValue} onClick={(e) => e.stopPropagation() } extraStyles={{ fontWeight: 700 }} />
                )}
              </>
            )}
            { result.coverageType == "coverage" && (
              <>
                <div style={{ display: "inline-block" }}>
                  <div style={{ display: "inline-block" }}>
                    <Text size={20}>
                      <b>Sublimit</b>: 
                    </Text>
                  </div>
                  <div style={{ marginLeft: "5px", display: "inline-block" }}>
                    { !editing && (
                      <Text size={20}>
                        {" " + currentSublimit}
                      </Text>
                    )}
                    { editing && (
                      <Input value={currentSublimit} setValue={setCurrentSublimit} onClick={(e) => e.stopPropagation() } />
                    )}
                  </div>
                </div>
                <div style={{ display: "inline-block" }}>
                  <div style={{ display: "inline-block" }}>
                    <Text size={20}>
                      <b>Retention</b>: 
                    </Text>
                  </div>
                  <div style={{ marginLeft: "5px", display: "inline-block" }}>
                    { !editing && (
                      <Text size={20}>
                        {" " + currentRetention}
                      </Text>
                    )}
                    { editing && (
                      <Input value={currentRetention} setValue={setCurrentRetention} onClick={(e) => e.stopPropagation() } />
                    )}
                  </div>
                </div>
              </>
            )}
            { result.coverageType == "endorsement" && (
              <>
                { !editing && (
                  <Text size={20} weight={selected ? 700 : 400}>
                    {currentDescription}
                  </Text>
                )}
                { editing && (
                  <Input inputType="textarea" value={currentDescription} setValue={setCurrentDescription} />
                )}
              </>
            )}
            { result.coverageType == "schedule" && (
              <>
                <Button border="1px solid black" color="white" padding="10px" onClick={() => onScheduleClick(result)}>
                  <Text size={20}>
                    View Schedule
                  </Text>
                </Button>
              </>
            )}
          </div>
        )}
      </>
    )
  );
};

// coverageType: coverage, endorsement, schedule, generic

/*
{ result.coverageType == "endorsement" && (
                
              )}
              { result.coverageType == "schedule" && (
                
              )}*/

export default ResultItem;
