import React from "react";

const Input = ({ value, setValue, extraStyles, onClick, inputType, rows }: {
  value: string,
  setValue: (s: string) => void,
  extraStyles?: any,
  onClick?: (() => void) | ((e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void),
  inputType?: string,
  rows?: number
}) => {

  if (inputType == "textarea") {
    return (
      <textarea
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        rows={rows ? rows : 10}
        style={{
          width: "100%",
          borderRadius: "8px",
          fontSize: "20px",
          padding: "0px",
          border: "none",
          outline: "none",
          fontFamily: "Assistant, sans-serif",
          ...extraStyles
        }}
        onClick={(e) => e.stopPropagation()}
      />
    )
  }

  return (
    <input
      type="text"
      value={value}
      onChange={(e) => setValue(e.currentTarget.value)}
      style={{
        width: "100%",
        borderRadius: "8px",
        fontSize: "20px",
        padding: "0px",
        border: "none", //"1px solid #475569",
        outline: "none",
        fontFamily: "Assistant, sans-serif",
        ...extraStyles
      }}
      onClick={onClick}
    />
  )
}

export default Input;