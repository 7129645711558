import React, { useState, useRef, useEffect, ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
//import { Analytics } from '@vercel/analytics/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Scrollbar } from "react-scrollbars-custom";
import Select, { components } from 'react-select';

import Spacer from "./components/base/Spacer";
import Space from "./components/base/Space";
import Text from "./components/base/Text";
import "./LandingPage.css";

import AppHeader from "./components/AppHeader";
import BottomBar from "./components/BottomBar";
import LoadingSpinner from "./components/base/Spinner";
import Container from "./components/Container";
import Document, { DocumentStatus, DocumentType } from "./components/Document";
import NewDocument from "./components/NewDocument";
import FileDrop from "./components/FileDrop";

import PaginationFooter from "./components/PaginationFooter";
import Search from "./components/Search";
import ListDocument from "./components/ListDocument";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Divider from "./components/base/Divider";
import Button from "./components/base/Button";

const SortBarElement = React.memo(({ name, sort, setSort }: { name: string, sort: string, setSort: (s: string) => void}) => {
  const [stateIndex, setStateIndex] = useState<number>(0);
  const states = ["", name.toLowerCase(), `${name.toLowerCase()}-reverse`];
  
  const changeState = () => {
    const newStateIndex = (stateIndex + 1) % states.length;
    setSort(states[newStateIndex]);
    setStateIndex(newStateIndex);
  }

  useEffect(() => {
    if (!states.includes(sort)) {
      setStateIndex(0);
    }
  }, [sort])

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          justifyContent: "flex-start",
          cursor: "pointer",
          alignItems: "center"
        }}
        onClick={changeState}
      >
        <Text size={20} weight={600}>{name}</Text>
        { states[stateIndex] != "" && (
          <img 
            style={{ width: "15px", height: "15px", rotate: states[stateIndex] == name.toLowerCase() ? "90deg" : "270deg" }}
            src={process.env.PUBLIC_URL + `/assets/thick-arrow.png`}
          />
        )}
      </div>
    </>
  )
})

export const VerticalDivider = ({ height }: { height?: string }) => {
  return (
    <div style={{
      height: height ?? "25px",
      width: "1px",
      //backgroundColor: "#E3E8EF",
      backgroundColor: "black",
      flexShrink: 0,
    }} />
  )
}

export const SortBar = React.memo(({ sort, setSort }: { sort: string, setSort: (s: string) => void}) => {

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{
        width: "50%",
        marginLeft: "calc(5% + 10px)"
      }}>
        <SortBarElement name="Name" sort={sort} setSort={setSort} />
      </div>
      <div style={{
        marginLeft: "-5px"
      }}>
        <VerticalDivider />
      </div>
      <div style={{
        width: "15%",
        marginLeft: "10px"
      }}>
        <SortBarElement name="Status" sort={sort} setSort={setSort} />
      </div>
      <div style={{
        marginLeft: "-5px"
      }}>
        <VerticalDivider />
      </div>
      <div style={{
        width: "15%",
        marginLeft: "10px"
      }}>
        <SortBarElement name="Created At" sort={sort} setSort={setSort} />
      </div>
    </div>
  )
})

export const SortDropdown = React.memo(({ sort, setSort }: { sort: string, setSort: (s: string) => void}) => {

  const sortOptions = [
    {
      label: "Name (a to z)",
      value: "name"
    },
    {
      label: "Name (z to a)",
      value: "name-reverse"
    },
    {
      label: "Status",
      value: "status"
    },
    {
      label: "Status (reverse)",
      value: "status-reverse"
    },
    {
      label: "Created At (first to last)",
      value: "created at-reverse"
    },
    {
      label: "Created At (last to first)",
      value: "created at"
    },
  ]
  
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: '45px',
      width: '200px',
      fontSize: '16px',
      fontFamily: 'Assistant',
      border: "none",
      outline: "none"
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: 'Assistant', // Set your desired font family
      fontSize: '16px',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: 'Assistant', // Ensure consistency in selected value
      fontSize: '16px',
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontFamily: 'Assistant', // Ensure consistency in selected value
      fontSize: '16px',
    })
  };
  

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "12px", width: "100%", justifyContent: "flex-end", alignItems: "center" }}>
      <Text size={16}>
        Sort by:
      </Text>
      <Select
        value={sortOptions.find(c => c.value == sort)}
        onChange={(option) => {
          setSort(option?.value ?? "created at");
        }}
        options={sortOptions}
        styles={customStyles}
      />
    </div>
  )
})

export const DocumentsContainer = React.memo(({ clientId, category, documents, retrieveDocuments, onDocumentClick, setClientId, setErrorMessage, selecting, selectedDocument, onFirstClick }: {
  clientId: string,
  category: string,
  documents: DocumentType[],
  retrieveDocuments: () => void,
  onDocumentClick: (d: DocumentType) => void,
  setClientId: (s: string) => void,
  setErrorMessage: (s: string) => void,
  selecting?: boolean,
  selectedDocument?: DocumentType,
  onFirstClick?: () => void
}) => {
  const [viewType, setViewType] = useState<string>("list");
  const [query, setQuery] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [sort, setSort] = useState<string>("");

  const [localDocuments, setLocalDocuments] = useState<DocumentType[]>([]);
  const [policyDocuments, setPolicyDocuments] = useState<DocumentType[]>([]);
  const [filteredDocuments, setFilteredDocuments] = useState<DocumentType[]>([]);

  useEffect(() => {
    let lcl: DocumentType[] = [];
    let plc: DocumentType[] = [];
    documents.forEach(d => {
      if (d.category == category) lcl.push(d);
      else if (d.category == "policy") plc.push(d);
    })

    setLocalDocuments(lcl.filter(d => !d.associatedDocumentId));
    setPolicyDocuments(plc.filter(d => !d.associatedDocumentId))
  }, [documents])

  useEffect(() => {
    setPage(0);
    onSearch();
  }, [query, localDocuments, sort])

  useEffect(() => {
    onSearch();
  }, [page, itemsPerPage])

  const handleViewType = (
    event: React.MouseEvent<HTMLElement>,
    newViewType: string | null,
  ) => {
    setViewType(newViewType ?? "files");
  };

  const onSearch = () => {
    // setPage(0);
    const statusOrder = ["analyzed", "generated", "analyzing", "generating", "uploaded", "uploading", "failed"];
    setFilteredDocuments(
      localDocuments.filter(p => (
        p.name.toLowerCase().includes(query.toLowerCase())
      )).filter(d => (
        !selecting || (d.category == "policy" && d.status == "analyzed")
      )).sort((a, b) => {
        if (sort === "name") {
          return a.name.localeCompare(b.name);
        } else if (sort === "name-reverse") {
          return b.name.localeCompare(a.name);
        } else if (sort === "status") {
          return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
        } else if (sort === "status-reverse") {
          return statusOrder.indexOf(b.status) - statusOrder.indexOf(a.status);
        } else if (sort === "created at") {
          const dateA = a.createdAt ? new Date(a.createdAt).getTime() : new Date(0).getTime(); // Use epoch date if undefined
          const dateB = b.createdAt ? new Date(b.createdAt).getTime() : new Date(0).getTime(); // Use epoch date if undefined
          return dateB - dateA;
        } else if (sort === "created at-reverse") {
          const dateA = a.createdAt ? new Date(a.createdAt).getTime() : new Date(0).getTime(); // Use epoch date if undefined
          const dateB = b.createdAt ? new Date(b.createdAt).getTime() : new Date(0).getTime(); // Use epoch date if undefined
          return dateA - dateB;
        } 
        return 0;
      }
    ).slice(page * itemsPerPage, (page + 1) * itemsPerPage));
  }

  return (
    <>
      { localDocuments.length > 0 && (
        <>
          <div style={{ display: "flex", flexDirection: "row", gap: "30px"}}>
            <Search onSearch={() => { setPage(0); onSearch(); }} query={query} setQuery={setQuery} />
            <ToggleButtonGroup
              value={viewType}
              exclusive
              onChange={handleViewType}
              aria-label="text alignment"
            >
              <ToggleButton value="files" aria-label="files">
                <img style={{ width: "20px", height: "20px" }} src={process.env.PUBLIC_URL + `/assets/squares.png`} />
              </ToggleButton>
              <ToggleButton value="list" aria-label="list">
              <img style={{ width: "20px", height: "20px" }} src={process.env.PUBLIC_URL + `/assets/list.png`} />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <Space px={selecting ? 0 : 20} />
        </>
      )}
      { (category == "policy" || localDocuments.length > 0) && (
        <>
        <FileDrop documentType="policies" documentsLength={localDocuments.length} retrieveDocuments={retrieveDocuments} category={category} clientId={clientId} setErrorMessage={setErrorMessage} setClientId={setClientId} noMinHeight>
          { viewType == "files" && (
            <>
              { localDocuments.length > 0 && (
                <>
                  <SortDropdown sort={sort} setSort={setSort} /> 
                  <Space px={10} />
                  <Divider />
                </>
              )}
              <Space px={10} />
              <div style={{ display: "flex", flexDirection: "row", gap: "35px", flexWrap: "wrap", width: "100%", justifyContent: localDocuments.length == 0 ? "center" : undefined }}>
                { filteredDocuments.map(d => (
                  <div style={{ cursor: "pointer" }} onClick={selecting ? () => onDocumentClick(d) : undefined}>
                    <Document key={d.instanceId} d={d} retrieveDocuments={retrieveDocuments} onClick={onDocumentClick} selecting={!!selecting} selected={selectedDocument?.instanceId == d.instanceId} />
                  </div>
                ))}
                { (category == "policy" && !selecting) && <NewDocument documentsLength={localDocuments.length} retrieveDocuments={retrieveDocuments} category={category} clientId={clientId} /> }
              </div>
            </>
          )}
          { viewType == "list" && (
            <>
              { localDocuments.length > 0 && (
                <>
                  <SortBar sort={sort} setSort={setSort} /> 
                  <Space px={10} />
                  <Divider />
                </>
              )}
              <Space px={10} />
              <div style={{ display: "flex", flexDirection: "column", gap: "5px", width: "100%", justifyContent: localDocuments.length == 0 ? "center" : undefined }}>
                { filteredDocuments.map(d => (
                  <div style={{ cursor: "pointer" }} onClick={selecting ? () => onDocumentClick(d) : undefined}>
                    <ListDocument key={d.instanceId} d={d} retrieveDocuments={retrieveDocuments} onClick={onDocumentClick} selecting={!!selecting} selected={selectedDocument?.instanceId == d.instanceId} />
                  </div>
                ))}
                { (category == "policy" && !selecting) && <NewDocument documentsLength={localDocuments.length} retrieveDocuments={retrieveDocuments} category={category} clientId={clientId} isList={true} /> }
              </div>
            </>
          )}
          { localDocuments.length > 0 && (
            <>
              <Space px={50} />
              <PaginationFooter items={localDocuments.filter(d => (!selecting || (d.category == "policy" && d.status == "analyzed")))} page={page} setPage={setPage} itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} nonSticky={!!selecting} />
            </>
          )}
        </FileDrop>
        { selecting && (
          <Space px={10} />
        )}
        </>
      )}
      { (category != "policy" && localDocuments.length == 0) && (
        <div style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          marginTop: "140px"
        }}>
          { policyDocuments.filter(d => d.status == "analyzed").length > 0 && (
            <>
              <Text size={30} color="#787878" align="center">
                You haven't generated a {category} yet.
              </Text>
              <Button padding="15px" border="1px solid black" color="white" onClick={onFirstClick}>
                <Text size={18} weight={600}>
                  Generate your first
                </Text>
              </Button>
            </>
          )}
          { policyDocuments.filter(d => d.status == "analyzed").length <= 0 && (
            <>
              <Text size={30} color="#787878" align="center">
                You must first analyze a policy to create a {category == "analysis" ? "report" : category}.
              </Text>
            </>
          )}
          
        </div>
      )}
    </>

  )

})

const Documents = React.memo(({ clientId, setClientId, agencyId, documents, retrieveDocuments, loadingDocuments, onDocumentClick, setErrorMessage, isSidebarOpen }: { 
  clientId: string,
  agencyId: string,
  setClientId: (s: string) => void,
  documents: DocumentType[],
  retrieveDocuments: () => void,
  loadingDocuments: boolean,
  onDocumentClick: (d: DocumentType) => void,
  setErrorMessage: (s: string) => void,
  isSidebarOpen: boolean,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies(['user-id']);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  return (
    <div style={{
      position: "fixed",
      height: "100%",
      top: "100px",
      left: isSidebarOpen ? "205px" : "40px",
      padding: "53px 60px 0px 60px",
      transition: "left 0.3s ease"
    }}>
      <Scrollbar style={{ width: isSidebarOpen ? "calc(100vw - 270px)" : "calc(100vw - 105px)", height: "100%", transform: "translateY(-60px)", transition: "width 0.3s ease" }}>
      <div style={{ transform: "translateY(60px)"}}>
        <Text size={40} color="#787878">
          Policies
        </Text>
        <Space px={25} />
        <Container key="policyContainer" minHeight="375px" title="Policy Documents" zIndex={9} width={isSidebarOpen ? "calc(100vw - 400px)" : "calc(100vw - 235px)"}>
          { loadingDocuments && (
            <>
              <Space px={140} />
              <LoadingSpinner />
            </>
          )}
          { !loadingDocuments && (
            <DocumentsContainer clientId={clientId} category="policy" documents={documents} retrieveDocuments={retrieveDocuments} onDocumentClick={onDocumentClick} setClientId={setClientId} setErrorMessage={setErrorMessage} />
          )}
        </Container>
        <Space px={400} />
      </div>
      </Scrollbar>
    </div>
  );
});

export default Documents;
